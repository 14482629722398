@media screen and (max-width: 1000px) {
  .issues_cards {
    justify-content: center !important;
  }
}
@media screen and (max-width: 600px) {
  .issues_cards {
    justify-content: center !important;
  }
}

/* @media screen and (max-width: 280px) {
    .issues_cards {
      justify-content: center !important;
    }
  } */

.issues {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.issues_cards {
  width: 90%;
  margin: 30px 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}
.issues_details {
  width: 90%;
  gap: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 30px 0;
}
.issues_details > p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8rem;
}
