* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
:root {
  --blue-color: #0267ff;
  --white-color: #fff;
  --blackshade-color: #030303;
  --active-color: #0f823f;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
h3 {
  font-size: 28px;
  font-weight: 600;
  font-family: "Playfair Display";
  color: var(--blackshade-color);
}
.active {
  color: var(--active-color) !important;
}
.button {
  padding: 10px 12px;
  outline: none;
  border: none;
  border-radius: 5px;
  color: var(--white-color);
  background-color: var(--blue-color);
  font-size: 18px;
  cursor: pointer;
}
.button:hover {
  transition: all 0.5s ease-in-out 0s;
  color:var(--blue-color);
  background-color:  var(--white-color);
  border: 1px solid var(--blue-color);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.breadcrums {
  width: 100%;
  padding-left: 5%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  font-family: "Playfair Display";
}
.breadcrums > a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.68);
}
.breadcrums > p {
  text-decoration: none;
  color: var(--blackshade-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
