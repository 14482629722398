@media screen and (max-width: 1000px) {
}

@media screen and (max-width: 850px) {
  .contact {
    flex-direction: column;
  }
  .contact_left {
    justify-content: center !important;
    align-items: center !important;
    gap: 30px !important;
  }
  .form_dual {
    flex-direction: column !important;
  }
  .form_dual > span > input {
    font-size: 45px !important;
    height: 65px !important;
  }
  .contact_right {
    margin-top: 30px;
    flex-direction: row !important;
  }
  /* .contact_right_span_p {
    font-size: 25px !important;
  } */
  .contact_right_span_p1 {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 600px) {
  .contact {
    flex-direction: column;
  }
  .contact_left {
    justify-content: center !important;
    align-items: center !important;
    gap: 30px !important;
  }
  .form_dual {
    flex-direction: column !important;
  }
  .form_dual > span > input {
    font-size: 25px !important;
  }
  .contact_right {
    margin-top: 30px;
    flex-direction: column !important;
  }
  /* .contact_right_span_p {
    font-size: 25px !important;
  } */
  .contact_right_span_p1 {
    font-size: 25px !important;
  }
}

.contact {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 30px 0;
}
.contact_left {
  flex: 0.5;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
}
.contact_left > form {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
}
.form_dual {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form_dual > span {
  flex: 0.45;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
}
.form_dual > span > input {
  width: 100%;
  font-size: 18px;
  height: 45px;
  border: none;
  outline: none;
  border-bottom: 2px solid var(--blackshade-color);
}
.form_dual > span > input::-ms-input-placeholder {
  font-size: 18px;
  color: var(--blackshade-color);
}
.form_dual > span > input::placeholder {
  font-size: 18px;
  color: var(--blackshade-color);
}

.form_textarea {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.form_textarea > textarea {
  width: 70%;
  font-size: 18px;
  border: none;
  outline: none;
  border-bottom: 2px solid var(--blackshade-color);
  resize: none;
}
.form_textarea > textarea::placeholder {
  font-size: 18px;
  color: var(--blackshade-color);
}
.form_textarea > textarea::-ms-input-placeholder {
  font-size: 18px;
  color: var(--blackshade-color);
}
.contact_right {
  flex: 0.3;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}
.contact_right > span {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
}
.contact_right_icons {
  flex-direction: row !important;
  gap: 20px;
}
.contact_right_span_p {
  font-size: 20px;
  font-weight: 700;
}
.contact_right_span_p1 {
  font-size: 18px;
  font-weight: 500;
  color: var(--blackshade-color);
}
