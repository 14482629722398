@media screen and (max-width: 1000px) {
  .nav-icons {
    display: none !important;
  }
}

@media screen and (max-width: 790px) {
  .nav_left > span > strong {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 635px) {
  .nav_left > span > strong {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 600px) {
  .nav-icons {
    display: none !important;
  }
  .nav_right {
    display: none !important;
  }
  .container {
    justify-content: space-between !important;
    padding-left: 10px;
    padding-right: 35px;
  }
  .nav_left {
    flex: none !important;
  }

  .nav_left > a > img {
    height: 100px !important;
  }
  .nav_left > span > strong {
    font-size: 18px !important;
  }
  .nav_left > span {
    margin-left: 6px !important;
    width: 50%;
  }
  .nav_left > span > p {
    font-size: 15px !important;
  }

  .hamberger {
    display: block !important;
  }

  .slide-left {
    -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  /* ----------------------------------------------
 * Generated by Animista on 2024-1-25 12:59:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-left
 * ----------------------------------------
 */
  @-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(-20px);
      transform: translateX(-20px);
    }
    100% {
      -webkit-transform: translateX(-0px);
      transform: translateX(-0px);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(-20px);
      transform: translateX(-20px);
    }
    100% {
      -webkit-transform: translateX(-0px);
      transform: translateX(-0px);
    }
  }

  .mobile-hamburger {
    position: relative;
  }
  .mobile-nav {
    width: 150px;
    /* height: 22vh; */
    position: absolute;
    right: -33px;
    top: 53px;
    background: white;
    list-style-type: none;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 5px;
  }
  .mobile-nav > li > a {
    text-decoration: none;
    color: var(--blackshade-color);
  }
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
  position: sticky;
  top: 0;
  z-index: 1111111;
}
.container {
  width: 90%;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100px;
}
.nav_left {
  flex: 0.45;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav_left > a > img {
  height: 120px;
  object-fit: contain;
}
.nav_left > span {
  margin-left: 20px;
}
.nav_left > span > strong {
  font-size: 25px;
  font-weight: 700;
  color: black;
}
.nav_left > span > p {
  font-size: 12px;
  font-weight: 600;
  color: #b0b0b0;
}
.nav_right {
  flex: 0.45;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav_right > ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.nav_right > ul > li {
  list-style: none;
}
.nav_left > img {
  height: 120px;
  object-fit: contain;
  overflow-y: visible;
}
.nav_right_ul > li {
  color: #2b2b2b;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.nav_right_ul > li > a {
  text-decoration: none;
  color: var(--blackshade-color);
}

.hamberger {
  display: none;
}
