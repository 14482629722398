@media only screen and (max-width: 600px) {
  .home_about {
    flex-direction: column !important;
  }
  .home_about_right {
    margin-top: 20px;
  }

  .home_about_right_img > img {
    position: static !important;
  }
  .home_issues {
    flex-direction: column;
  }

  .home_social-media_box {
    flex-direction: column;
  }
  .home_social-media > img {
    width: 100% !important;
  }
}

.home {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.home_about {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
}
.home_about_left {
  flex: 0.5;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  line-height: 1.5rem;
}
.home_about_left > h3 {
  margin-bottom: 20px;
}
.home_about_right {
  flex: 0.4;
  width: 100%;

  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_about_right_img {
  width: 70%;
  height: 100%;
  background: linear-gradient(
    154deg,
    #006f00 -66.04%,
    #fff 34.8%,
    #ff8700 129.74%
  );
  position: relative;
}
.home_about_right_img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 10px;
  bottom: 10px;
}
.home_issues {
  margin: 30px 0;
  width: 90%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: url("../../../Assest/Images/Issues.png");
  border-radius: 5px;
}
.home_issues_left {
  flex: 0.45;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  color: var(--white-color);
}
.home_issues_left > p {
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 22px;
  font-weight: 600;
}
.home_issues_left > p > span {
  width: 10px;
  height: 10px;
  background-color: var(--white-color);
  border-radius: 50%;
}
.home_issues_left > h3 {
  color: var(--white-color);
  line-height: 2.5rem;
}
.home_issues_left > h3 > span {
  color: var(--blue-color);
}
.home_issues_left > a > button {
  padding: 10px 12px;
  border: transparent;
  outline: transparent;
  background-color: var(--white-color);
  color: var(--blue-color);
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
}
.home_issues_right {
  flex: 0.35;
}
.home_issues_right > ul {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 25px;
}
.home_issues_right > ul > li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  color: var(--white-color);
  font-size: 18px;
  font-weight: 400;
}

.home_flag {
  width: 100%;
}
.home_flag > img {
  width: 100%;
}

.home_social-media_box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    113.07deg,
    rgba(255, 126, 0, 0.85) -26.16%,
    #ffffff 46.67%,
    #007d00 111.19%
  );
  gap: 15px;
  width: 90%;
  border-radius: 10px;
  padding: 35px;
  margin-bottom: 30px;
}
.home_social-media {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}
.home_social-media > img {
  width: 60%;
  border-radius: 10px;
}
