@media screen and (max-width: 900px) {
  .about_container {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 600px) {
  .about_container {
    flex-direction: column;
  }
}

.about {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.about_container,
.about_container1 {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 30px 0;
}
.about_container1 {
  align-items: flex-start !important;
  justify-content: flex-start !important;
  gap: 20px;
}
.about_container_left {
  flex: 0.55;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
}
.about_container_left > p,
.about_container1 > p {
  line-height: 1.8rem;
  font-size: 18px;
  font-weight: 500;
}
.about_container_right {
  flex: 0.45;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about_container_right > img {
  height: 350px;
  width: 90%;
  object-fit: cover;
}
