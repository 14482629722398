.issuePageCard {
  width: 300px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  color: var(--white-color);
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
}
.issuePageCard>a{
    text-decoration: none;
    color: var(--white-color);
}